import React, { useEffect, useState } from 'react'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import OverlayLoading from 'app/components/OverlayLoading'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import { toast } from 'react-hot-toast'
import Tooltip from 'app/components/Tooltip'
import { getBaseUrl, SERVICES } from 'app/services/BaseService'
import { useAnalytics } from 'app/hooks'

interface DashboardProps {
    reportId: string
    datasetId: string
    workspaceId: string
    title: string
    identities?: any[]
    onLoad?: () => void
    onError?: (error: any) => void
    onVisualClicked?: (event: any) => void
    onDataSelected?: (event: any) => void
    onSelectionChanged?: (event: any) => void
}

export function PowerBIDashboardPage({ reportId, datasetId, workspaceId, title, identities = [], onLoad, onError, onVisualClicked, onDataSelected, onSelectionChanged }: DashboardProps) {
    const analytics = useAnalytics()
    const [loading, setLoading] = useState<boolean>(false)
    const [embedData, setEmbedData] = useState<any>(null)
    const [reportNotFound, setReportNotFound] = useState<boolean>(false)

    useEffect(() => {
        if (reportId != null) {
            let baseUrl = getBaseUrl(SERVICES.CONNECTOR_BACKEND)
            if (baseUrl.endsWith('/')) baseUrl = baseUrl.slice(0, -1)
            let url = `${baseUrl}/azure/reports/getEmbedToken?reportId=${reportId}&datasetId=${datasetId}&workspaceId=${workspaceId}`

            if (identities && identities.length > 0) {
                if (typeof identities === 'object') {
                    url += `&identities=${JSON.stringify(identities)}`
                } else {
                    url += `&identities=${identities}`
                }
            }

            setLoading(true)
            fetch(url)
                .then(res => res.json())
                .then(result => {
                    if (result != null) {
                        analytics.trackEvent('Navigation', {
                            type: window.location.pathname,
                        })

                        if (result?.token) {
                            setEmbedData({
                                accessToken: result.token,
                                embedUrl: result.embedUrl,
                                reportId: result.reportId,
                            })
                        } else {
                            toast.error('Error al cargar el reporte. Inténtelo nuevamente en unos minutos.')
                            onError && onError(result)

                            setReportNotFound(true)
                        }

                        setLoading(false)
                    }
                })
                .catch((err: any) => {
                    toast.error('Error al cargar el reporte. Inténtelo nuevamente en unos minutos.')
                    onError && onError(err)
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId])

    return (
        <div style={{ position: 'relative', padding: 10, minHeight: 200 }}>
            <h1 style={{ marginBottom: 20 }}>
                {title}
                <Tooltip
                    title={
                        <>
                            <p className={'m-2 text-center'}>
                                ID de reporte: <b>{reportId}</b>
                            </p>
                            <p className={'m-2 text-center'}>
                                ID de dataset: <b>{datasetId}</b>
                            </p>
                            <p className={'m-0 text-center'}>
                                ID de workspace: <b>{workspaceId}</b>
                            </p>
                        </>
                    }>
                    <HelpOutlineIcon style={{ marginLeft: 10, fontSize: '0.9em', color: 'rgba(0,0,0,.5)' }} />
                </Tooltip>
            </h1>

            {reportNotFound && <h4 style={{ textAlign: 'center', color: 'rgba(0,0,0,.3)', marginTop: 10 }}>No se pudo cargar el reporte solicitado</h4>}

            {embedData != null && (
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report', // Supported types: report, dashboard, tile, visual and qna
                        id: embedData.reportId,
                        embedUrl: embedData.embedUrl,
                        accessToken: embedData.accessToken,
                        tokenType: models.TokenType.Embed,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false,
                                },
                            },
                            /* background: models.BackgroundType.Transparent, */
                        },
                    }}
                    eventHandlers={
                        new Map([
                            ['loaded', (event: any) => onLoad && onLoad()],
                            [
                                'rendered',
                                (event: any) => {
                                    setLoading(false)
                                },
                            ],
                            [
                                'error',
                                (event: any) => {
                                    onError && onError(event)
                                },
                            ],
                            [
                                'visualClicked',
                                (event: any) => {
                                    onVisualClicked && onVisualClicked(event)
                                },
                            ],
                            [
                                'dataSelected',
                                (event: any) => {
                                    onDataSelected && onDataSelected(event)
                                },
                            ],
                            [
                                'selectionChanged',
                                (event: any) => {
                                    onSelectionChanged && onSelectionChanged(event)
                                },
                            ],
                        ])
                    }
                    cssClassName={'report-style-class'}
                    getEmbeddedComponent={embeddedReport => {}}
                />
            )}

            <OverlayLoading visible={loading} />
        </div>
    )
}
