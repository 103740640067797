/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import { shallowEqual, useSelector } from 'react-redux'

export function AsideMenuListAdministrator() {
    const location = useLocation()
    const { user } = useSelector(state => state.auth, shallowEqual)

    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
            : ''
    }

    const hasAccessToKPIS = () => {
        return user?.hasPermission('VIEW_KPIS')
    }

    const hasAccessToImportsDashboard = () => {
        return user?.hasPermission('VIEW_IMPORTS_DASHBOARD')
    }

    if (!user) return null

    return (
        <>
            <p style={{ margin: 0, color: 'rgba(255,255,255,.3)', fontSize: '1em', marginLeft: '10%', marginTop: '5%' }}>Panel Administrador</p>
            <ul className={`menu-nav`}>
                <li className={`menu-item ${getMenuItemActive('/admin/users', false)}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/admin/users">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
                        </span>
                        <span className="menu-text">Usuarios</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive('/admin/sources', false)}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/admin/sources">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
                        </span>
                        <span className="menu-text">Sources</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive('/admin/targets', false)}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/admin/targets">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
                        </span>
                        <span className="menu-text">Clientes</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive('/admin/pricings', false)}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/admin/pricings">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
                        </span>
                        <span className="menu-text">Precios</span>
                    </NavLink>
                </li>

                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive('/error', true)}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover">
                    <NavLink className="menu-link menu-toggle" to="/error">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Flatten.svg')} />
                        </span>
                        <span className="menu-text">Reportes</span>
                        <i className="menu-arrow" />
                    </NavLink>

                    <div className="menu-submenu ">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">
                            <li
                                className={`menu-item ${getMenuItemActive('/reports/sales', false, true)}`}
                                aria-haspopup="true">
                                <NavLink className="menu-link" to="/reports/sales" exact={true} style={{ opacity: .8 }}>
                                    <span className="menu-text">Dashboard Ventas</span>
                                </NavLink>
                            </li>

                            {hasAccessToKPIS() && (
                                <li
                                    className={`menu-item ${getMenuItemActive('/reports/forecast', false, true)}`}
                                    aria-haspopup="true">
                                    <NavLink className="menu-link" to="/reports/forecast" exact={true} style={{ opacity: .8 }}>
                                        <span className="menu-text">Dashboard Forecast</span>
                                    </NavLink>
                                </li>
                            )}

                            {hasAccessToImportsDashboard() && (
                                <li
                                    className={`menu-item ${getMenuItemActive('/reports/supplying', false, true)}`}
                                    aria-haspopup="true">
                                    <NavLink className="menu-link" to="/reports/supplying" exact={true} style={{ opacity: .8 }}>
                                        <span className="menu-text">Dashboard Abastecimiento</span>
                                    </NavLink>
                                </li>
                            )}

                            {user?.powerbi_reports?.length > 0 && user?.powerbi_reports.map((report, index) => {
                                return (
                                    <li
                                        className={`menu-item ${getMenuItemActive(`/reports/${report.id}`, false, true)}`}
                                        aria-haspopup="true">
                                        <NavLink className="menu-link" to={`/reports/${report.id}`} exact={true} style={{ opacity: .8 }}>
                                            <span className="menu-text">{report.title}</span>
                                        </NavLink>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </li>

                {/* <li
                    className={`menu-item ${getMenuItemActive(
                        '/admin/automated_alerts',
                        false
                    )}`}
                    aria-haspopup="true">
                    <NavLink className="menu-link" to="/admin/automated_alerts">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={toAbsoluteUrl(
                                    '/media/svg/icons/Design/Layers.svg'
                                )}
                            />
                        </span>
                        <span className="menu-text">Alertas Automáticas</span>
                    </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive(
                        '/admin/data/upload',
                        false
                    )}`}
                    aria-haspopup="true">
                    <NavLink className="menu-link" to="/admin/data/upload">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={toAbsoluteUrl(
                                    '/media/svg/icons/Design/Layers.svg'
                                )}
                            />
                        </span>
                        <span className="menu-text">Actualización de datos</span>
                    </NavLink>
                </li> */}
            </ul>
        </>
    )
}
