import React, { lazy } from "react";
import { Route as RouterRoute } from "react-router-dom";
import { motion } from "framer-motion";
import { DashboardPageByReportID } from "../pages/PowerBI/DashboardPageByReportID";
import { SalesDashboardPage } from "../pages/PowerBI/SalesDashboardPage";
import { ImportsDashboardPage } from "../pages/PowerBI/ImportsDashboardPage";
import { KPIsDashboardPage } from "../pages/PowerBI/KPIsDashboardPage";
import { CreateTicketPage } from "../pages/Tickets/create";

/* RTV and Sales */
const WorkPackageForecastVisualization = lazy(() => import("app/pages/WorkPackages/Forecast/visualization"));
const WorkPackageForecastSubmit = lazy(() => import("app/pages/WorkPackages/Forecast/submit"));
const WorkPackageForecastsValidate = lazy(() => import("app/pages/WorkPackages/Forecast/validate"));
const WorkPackageForecastsConsolidation = lazy(() => import("app/pages/WorkPackages/Forecast/consolidation"));
const WorkPackageForecastsVerificationRegion = lazy(() => import("app/pages/WorkPackages/Forecast/verification_region"));
const WorkPackageForecastsVerification = lazy(() => import("app/pages/WorkPackages/Forecast/verification"));
const WorkPackageForecastsList = lazy(() => import("app/pages/WorkPackages/Forecast/list"));
const WorkPackageForecastConfiguration = lazy(() => import("app/pages/WorkPackages/Forecast/configuration"));

/* Only administrator */
const AdminSourcesList = lazy(() => import("app/pages/Admin/Sources/list"));
const AdminTargetsList = lazy(() => import("app/pages/Admin/Targets/list"));
const AdminPricingsList = lazy(() => import("app/pages/Admin/Pricings/list"));
const AdminUsersList = lazy(() => import("app/pages/Admin/Users/list"));
const AdminUsersEdit = lazy(() => import("app/pages/Admin/Users/edit"));
const AdminRolesList = lazy(() => import("app/pages/Admin/Roles/list"));
const AdminRolesEdit = lazy(() => import("app/pages/Admin/Roles/edit"));
const AdminOrganizationsList = lazy(() => import("app/pages/Admin/Organizations/list"));
const AdminOrganizationsEdit = lazy(() => import("app/pages/Admin/Organizations/edit"));
const AdminDataUploadHome = lazy(() => import("app/pages/Admin/DataUpload/index"));
const AdminDataUploadLogs = lazy(() => import("app/pages/Admin/DataUpload/logs"));
const AdminModulesPage = lazy(() => import("app/pages/Admin/ModulesPage"));

const Route = (props: any) => {
    return (
        <motion.div
            initial={{ opacity: 0, translateY: 10 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: -10 }}
            style={{
                height: "100%",
                width: "100%",
            }}>
            <RouterRoute {...props} />
        </motion.div>
    );
};

export function AdministratorRoutes() {
    return [
        <Route path="/admin/users" exact component={AdminUsersList} />,
        <Route path="/admin/data/upload" exact component={AdminDataUploadHome} />,
        <Route path="/admin/data/logs" exact component={AdminDataUploadLogs} />,
        <Route path="/admin/sources" exact component={AdminSourcesList} />,
        <Route path="/admin/targets" exact component={AdminTargetsList} />,
        <Route path="/admin/pricings" exact component={AdminPricingsList} />,
        <Route path="/admin/users/:id" exact component={AdminUsersEdit} />,
        <Route path="/admin/roles" exact component={AdminRolesList} />,
        <Route path="/admin/roles/:id" exact component={AdminRolesEdit} />,
        <Route path="/admin/organizations" exact component={AdminOrganizationsList} />,
        <Route path="/admin/organizations/:id" exact component={AdminOrganizationsEdit} />,
        <Route path="/admin/modules" exact component={AdminModulesPage} />,
    ];
}

export function SourceRoutes() {
    return [
        <Route path="/forecasts" exact component={WorkPackageForecastsList} />,
        <Route path="/forecasts/visualization" exact component={WorkPackageForecastVisualization} />,
        <Route path="/forecasts/configuration" exact component={WorkPackageForecastConfiguration} />,
        <Route path="/forecasts/validate" exact component={WorkPackageForecastsValidate} />,
        <Route path="/forecasts/verification_region" exact component={WorkPackageForecastsVerificationRegion} />,
        <Route path="/forecasts/verification" exact component={WorkPackageForecastsVerification} />,
        <Route path="/forecasts/consolidation" exact component={WorkPackageForecastsConsolidation} />,
        <Route path="/forecasts/:id" exact component={WorkPackageForecastSubmit} />,
    ];
}

export function FinanceRoutes() {
    return [<Route path="/configuration" exact component={WorkPackageForecastConfiguration} />];
}

export function CommonRoutes() {
    return [
        <Route path="/tickets/create" component={CreateTicketPage} />,
        <Route path="/reports/sales" component={SalesDashboardPage} />,
        <Route path="/reports/forecast" component={KPIsDashboardPage} />,
        <Route path="/reports/kpis" component={KPIsDashboardPage} />,
        <Route path="/reports/supplying" component={ImportsDashboardPage} />,
        <Route path="/reports/:id" component={DashboardPageByReportID} />,
    ];
}
