import { ReportService } from 'app/services'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PowerBIDashboardPage } from '.'
import OverlayComponent from 'app/components/OverlayLoading'
import toast from 'react-hot-toast'

export function DashboardPageByReportID() {
    const params: any = useParams()
    const [report, setReport] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        const paramId = params?.id || 0
        if (paramId > 0) {
            setLoading(true)
            ReportService.getPowerBIReportById(paramId)
                .then(response => {
                    if (response.status == 200 && response.data && response.data.data) {
                        const data = response.data.data
                        setReport(data)
                    } else {
                        toast.error('No se encontró el reporte solicitado')
                    }

                    setLoading(false)
                })
                .catch(err => {
                    console.log(`An error occurred..`, err)
                    setLoading(false)
                })
        }
    }, [params?.id])

    if (loading) {
        return <OverlayComponent loading={true} />
    } else {
        if (report) {
            return <PowerBIDashboardPage reportId={report.report_id} datasetId={report.dataset_id} workspaceId={report.workspace_id} identities={report.identities} title={report.title} />
        }
    }

    return (
        <div>
            <h4 style={{ textAlign: 'center', color: 'rgba(0,0,0,.3)', marginTop: 10 }}>No se ha encontrado el reporte solicitado</h4>
        </div>
    )
}
