/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import { useSelector } from 'react-redux'
// import { KTUtil } from '_metronic/_assets/js/components/util'
import { UserService } from 'app/services'
import { useStores } from 'app/mobx/useStore'

export function AsideMenuList() {
    const location = useLocation()
    const { user } = useSelector(state => state.auth)
    const stores = useStores()

    // const [forecastMenuEnabled, isForecastMenuEnabled] = React.useState(false);
    const getMenuItemActive = (url, hasSubmenu = false, absolute = false) => {
        if (absolute) {
            return location.pathname == url
                ? `${!hasSubmenu && '   menu-item-active'} menu-item-open menu-item-not-hightlighted`
                : ''
        }

        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
            : ''
    }

    const hasPrivateAccess = () => {
        return user?.hasRole(UserService.roles().APP_PRECIOS)
    }

    const hasAccessToKPIS = () => {
        return user?.hasPermission('VIEW_KPIS')
    }

    const hasAccessToImportsDashboard = () => {
        return user?.hasPermission('VIEW_IMPORTS_DASHBOARD')
    }

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav`}>
                {hasPrivateAccess() && (
                    <li
                        className={`menu-item ${getMenuItemActive('/dashboard/app_precios', false)}`}
                        aria-haspopup="true">
                        <NavLink className="menu-link" to="/dashboard/app_precios">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
                            </span>
                            <span className="menu-text">App Precios</span>
                        </NavLink>
                    </li>
                )}

                {hasAccessToKPIS() && (
                    <li className={`menu-item ${getMenuItemActive('/reports/forecast', false, true)}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/reports/forecast" exact={true}>
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Flatten.svg')} />
                            </span>
                            <span className="menu-text">Dashboard Forecast</span>
                        </NavLink>
                    </li>
                )}

                {hasAccessToImportsDashboard() && (
                    <li className={`menu-item ${getMenuItemActive('/reports/supplying', false, true)}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/reports/supplying" exact={true}>
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Flatten.svg')} />
                            </span>
                            <span className="menu-text">Dashboard Abastecimiento</span>
                        </NavLink>
                    </li>
                )}

                {user?.powerbi_reports?.length > 0 && user?.powerbi_reports?.map((report) => {
                    return (
                        <li className={`menu-item ${getMenuItemActive(`/reports/${report.id}`, false, true)}`} aria-haspopup="true">
                            <NavLink className="menu-link" to={`/reports/${report.id}`} exact={true}>
                                <span className="svg-icon menu-icon">
                                    <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Flatten.svg')} />
                                </span>
                                <span className="menu-text">{report.title}</span>
                            </NavLink>
                        </li>
                    )
                })}

                {!user?.hasRole('marketing') && !user.hasRole('external') &&
                    <li className={`menu-item ${getMenuItemActive('/reports/sales', false, true)}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/reports/sales" exact={true}>
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Flatten.svg')} />
                            </span>
                            <span className="menu-text">Dashboard Ventas</span>
                        </NavLink>
                    </li>
                }

                {(user.hasRole('vendedor') || user.hasRole('regional')) && (
                    <li
                        className={`menu-item ${getMenuItemActive('/forecasts/visualization', false, true)}`}
                        aria-haspopup="true">
                        <NavLink className="menu-link" to="/forecasts/visualization" exact={true}>
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Text/Filter.svg')} />
                            </span>
                            <span className="menu-text">Ver Forecast Consolidado</span>
                        </NavLink>
                    </li>
                )}

                {!user?.hasRole('marketing') && !user.hasRole('external') &&
                    <li className={`menu-item ${getMenuItemActive('/forecasts', false, true)}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/forecasts" exact={true}>
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')} />
                            </span>
                            <span className="menu-text">Listado de Forecasts</span>
                        </NavLink>
                    </li>
                }

                {(user.hasRole('vendedor') || user.hasRole('regional')) && !stores.user.isActingAsRegional() && (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/error', true)}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover">
                        <NavLink className="menu-link menu-toggle" to="/error">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Plus.svg')} />
                            </span>
                            <span className="menu-text">Crear Forecast</span>
                            <i className="menu-arrow" />
                        </NavLink>

                        <div className="menu-submenu ">
                            <i className="menu-arrow" />
                            <ul className="menu-subnav">
                                {(user.hasRole('vendedor') || user.hasRole('regional')) && (
                                    <li
                                        className={`menu-item ${getMenuItemActive('/forecasts/submit', true)}`}
                                        aria-haspopup="true">
                                        <NavLink className="menu-link" to="/forecasts/submit">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                            </i>
                                            <span className="menu-text">Crear Forecast Cliente</span>
                                        </NavLink>
                                    </li>
                                )}

                                {(user.hasRole('vendedor') || user.hasRole('regional')) && (
                                    <li
                                        className={`menu-item ${getMenuItemActive(
                                            '/forecasts/submit?view_mode=sabana',
                                            true
                                        )}`}
                                        aria-haspopup="true">
                                        <NavLink className="menu-link" to="/forecasts/sabana">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                            </i>
                                            <span className="menu-text">Crear Forecast Sábana</span>
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </li>
                )}

                {(user.hasRole('finance') || user.hasRole('sales')) && (
                    <li
                        className={`menu-item ${getMenuItemActive('/forecasts/configuration', true)}`}
                        aria-haspopup="true">
                        <NavLink className="menu-link" to="/forecasts/configuration">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Settings4.svg')} />
                            </span>
                            <span className="menu-text">Configuración</span>
                        </NavLink>
                    </li>
                )}

                {stores.user.managerOf && stores.user.managerOf.length > 0 && stores.user.isActingAsRegional() && (
                    <li
                        className={`menu-item ${getMenuItemActive('/forecasts/verification_region', false, true)}`}
                        aria-haspopup="true">
                        <NavLink className="menu-link" to="/forecasts/verification_region" exact={true}>
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Clipboard-list.svg')} />
                            </span>
                            <span className="menu-text">Validar región</span>
                        </NavLink>
                    </li>
                )}

                {false && (
                    <li className={`menu-item ${getMenuItemActive('/faq', false)}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/faq">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Flag.svg')} />
                            </span>
                            <span className="menu-text">Ayuda / Preguntas frecuentes</span>
                        </NavLink>
                    </li>
                )}
            </ul>
        </>
    )
}
