import React, { useEffect, useState } from 'react'

// @ts-ignore
import { useAnalytics } from 'app/hooks'
import { PowerBIDashboardPage } from '.'

export function KPIsDashboardPage() {
    const analytics = useAnalytics()
    const [ready, isReady] = useState(false)
    const [reportId] = useState<any>(`4d28b4a9-4e1a-4029-a78e-a78e1df6e425`)
    const [datasetId] = useState<any>(`226e73e1-f92e-480d-9526-ddb8d1ac4582`)
    const [workspaceId] = useState<any>(`4dd6453c-8861-4074-af90-1b2febc4a935`)

    useEffect(() => {
        // Logic to get the report ID based on the current user / role id
        // const roleIds = user.powerbi_report.role_id.split(',');
        analytics.trackEvent('Navigation', {
            type: window.location.pathname,
        })
        isReady(true)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (ready) {
        return <PowerBIDashboardPage reportId={reportId} datasetId={datasetId} workspaceId={workspaceId} title={'Dashboard de Forecast'} />
    }

    return null
}
