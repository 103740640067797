import { ApiService, getBaseUrl, SERVICES } from './BaseService'

export const ReportService = {
    getPowerBIReportById: (id: any) => {
        return ApiService(SERVICES.CONNECTOR_BACKEND).get(`reports/${id}`)
    },
    create: (data: any) => {
        return ApiService(SERVICES.CONNECTOR_BACKEND).post('reports', data)
    },
    deleteById: (id: any) => {
        return ApiService(SERVICES.CONNECTOR_BACKEND).delete(`reports/${id}`)
    },
    getEmbedData: (reportId: any, datasetId: any, workspaceId: any, identities: any) => {
        let baseUrl = getBaseUrl(SERVICES.CONNECTOR_BACKEND)
        if (baseUrl.endsWith('/')) baseUrl = baseUrl.slice(0, -1)
        let url = `${baseUrl}/azure/reports/getEmbedToken?reportId=${reportId}&datasetId=${datasetId}&workspaceId=${workspaceId}`

        if (identities && identities.length > 0) url += `&identities=${identities}`

        return fetch(url)
            .then(res => res.json())
            .then(result => {
                if (result != null) {
                    return {
                        accessToken: result.token,
                        embedUrl: result.embedUrl,
                        reportId: result.reportId,
                    }
                }
            })
            .catch((err: any) => {
                return null
            })
    },
}
