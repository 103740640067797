import React, { Suspense, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Redirect, Switch, Route } from 'react-router-dom'
import { LayoutSplashScreen } from '../_metronic/layout'
import { UserService } from 'app/services'

import { useAnalytics } from 'app/hooks'

// Private UPL
import SalesUPL from './pages/Unauthorized/SalesUPL'
import { useSelector } from 'react-redux'

import { CommonRoutes, AdministratorRoutes, SourceRoutes } from './routes/definitions'

export default function BasePage() {
    const { user } = useSelector(state => state.auth)
    const history = useHistory()
    const analytics = useAnalytics()

    useEffect(() => {
        if (analytics && history) {
            analytics.trackPageView(history.location.pathname)
            history.listen(location => {
                analytics.trackPageView(location.pathname)
            })
        }
    }, [analytics, history])

    // Update the isAdmin function to check for the ADMINISTRATOR role specifically
    const isAdmin = () => user?.hasRole(UserService.roles().ADMINISTRATOR)
    const isSales = () => user?.hasRole(UserService.roles().SALES) || user.hasRole(UserService.roles().APP_PRECIOS)
    const isRTV = () => user?.hasRole(UserService.roles().RTV)
    const isFinance = () => user?.hasRole(UserService.roles().FINANCE)
    const isSupply = () => user?.hasRole(UserService.roles().SUPPLY)

    // New function to determine default route
    const getDefaultRoute = () => {
        if (isAdmin() && !isSales() && !isRTV()) {
            return '/admin/users'
        }
        if (isSales() || isRTV() || isFinance() || isSupply()) {
            return '/forecasts'
        }

        return '/'
    }

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {/* Private UPL Zone */}
                {user && user.hasRole(UserService.roles().APP_PRECIOS) && (
                    <Route path="/dashboard/app_precios" component={SalesUPL} />
                )}

                {CommonRoutes()}
                {isAdmin() && AdministratorRoutes()}
                {(isSales() || isRTV() || isFinance() || isSupply()) && SourceRoutes()}

                <Redirect exact from="/" to={getDefaultRoute()} />
                <Redirect to={getDefaultRoute()} />
            </Switch>
        </Suspense>
    )
}
