import React, { useEffect, useState } from 'react'

import _ from 'lodash'

// @ts-ignore
import { shallowEqual, useSelector } from 'react-redux'
import { useStores } from 'app/mobx/useStore'
import { useAnalytics } from 'app/hooks'
import { UserService } from 'app/services'
import { PowerBIDashboardPage } from '.'

export function SalesDashboardPage() {
    const stores = useStores()
    const analytics = useAnalytics()
    const { user } = useSelector((state: any) => state.auth, shallowEqual)
    const [ready, setReady] = useState<boolean>(false)
    const [reportId] = useState<any>(`be8ce7dd-fee5-4214-8981-8fa09a83ecbd`)
    const [datasetId] = useState<any>(`6195a8fa-346a-4ff8-9d28-fbc334c367ab`)
    const [workspaceId] = useState<any>(`4dd6453c-8861-4074-af90-1b2febc4a935`)
    const [identities, setIdentities] = useState<any[]>([])

    useEffect(() => {
        // Logic to get the report ID based on the current user / role id
        // const roleIds = user.powerbi_report.role_id.split(',');
        let identities: any[] = []

        // For sales, supply and finance we need to set role = 'Admin'
        if (user?.hasRole('administrator') || user?.hasRole('sales') || user?.hasRole('finance') || user?.hasRole('supply') || user?.hasRole(UserService.roles().APP_PRECIOS)) {
            identities = [
                {
                    username: user.email,
                    roles: ['Admin'],
                },
            ]
        } else if (user && user.sources && user.sources.length > 0) {
            if (user.sources.length == 1) {
                // Exceptions for the powerbi reports
                // where we need to show multiple zones instead of just 1
                // in that case, we created separated reports on power bi
                const exceptions: any[] = [
                    { fromEmail: 'marion.garcia@upl-ltd.com', roleId: 'Zonas Marion' },
                    { fromEmail: 'gustavo.barbero@upl-ltd.com', roleId: 'Zonas Gustavo' },
                    { fromEmail: 'walter.palandri@upl-ltd.com', roleId: 'Zonas Palandri' },
                    { fromEmail: 'juanpablo.segovia@upl-ltd.com', roleId: 'Zonas Segovia' },
                ]

                // Show the zone for the respective RTV
                const source: any = _.find(stores.source.sources, (i: any) => i.id == user.wp_data.source_id)
                if (source && source.groups) {
                    // Check for exceptions
                    const exception: any = _.find(exceptions, (i: any) => i.fromEmail === user.email)
                    if (exception) {
                        identities = [
                            {
                                username: exception.fromEmail,
                                roles: [exception.roleId],
                            },
                        ]
                    } else {
                        identities = _.map(source.groups, (group: any) => {
                            return {
                                username: group.source_group_desc,
                                roles: ['Zonas'],
                            }
                        })
                    }
                }
            } else {
                // Regional
                const source: any = _.find(stores.source.sources, (i: any) => i.id == user.wp_data.source_id)
                if (source && source.groups) {
                    identities = [
                        {
                            username: _.uniq(_.map(source.groups, (i: any) => i.source_group_parent_code)).join('_'),
                            roles: _.uniq(_.map(source.groups, (i: any) => `Region_${i.source_group_parent_code}`)),
                        },
                    ]
                }
            }
        }

        analytics.trackEvent('Navigation', {
            type: window.location.pathname,
        })

        setIdentities(identities)
        setReady(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (ready) {
        // Use generic dashboard instead rendering again
        return <PowerBIDashboardPage reportId={reportId} datasetId={datasetId} workspaceId={workspaceId} title={'Dashboard de ventas'} identities={identities} />
    }

    return null
}
